export const dummyMurid = {
  data_murid: {
    nis: 91919001,
    ttl: 'Jakarta, 30 Mei 2007',
    nama: 'Abdullah Sami Assaqqaf',
    nisn: '0072924429',
    kelas: 'IX-3',
    id_kelas: 46,
    id_murid: 185,
    nomor_peserta: 2226760044,
    id_tahun_ajaran: 3,
    id_kelas_tahun_ajaran: 761,
  },
  data_nilai: {
    jumlah: 1060.3223684210527,
    rata_rata: 81.56325910931174,
    nilai_mapel: [
      {
        nama_group: 'A',
        mata_pelajaran: [
          {
            nama_mata_pelajaran: 'Pendidikan Agama dan Budi Pekerti',
            nilai: 84,
          },
          {
            nama_mata_pelajaran: 'Pendidikan Pancasila dan Kewarganegaraan',
            nilai: 78,
          },
          {
            nama_mata_pelajaran: 'Bahasa Indonesia',
            nilai: 82,
          },
          {
            nama_mata_pelajaran: 'Matematika',
            nilai: 77,
          },
          {
            nama_mata_pelajaran: 'Ilmu Pengetahuan Alam',
            nilai: 87,
          },
          {
            nama_mata_pelajaran: 'Ilmu Pengetahuan Sosial',
            nilai: 77,
          },
          {
            nama_mata_pelajaran: 'Bahasa Inggris',
            nilai: 90,
          },
        ],
      },
      {
        nama_group: 'B',
        mata_pelajaran: [
          {
            nama_mata_pelajaran: 'Seni Budaya',
            nilai: 81,
          },
          {
            nama_mata_pelajaran: 'Pendidikan Jasmani, Olahraga dan Kesehatan',
            nilai: 89,
          },
          {
            nama_mata_pelajaran: 'Prakarya',
            nilai: 77,
          },
          {
            nama_mata_pelajaran: 'Muatan Lokal',
            mata_pelajaran: [
              {
                nama_mata_pelajaran: 'TIK',
                nilai: 84,
              },
              {
                nama_mata_pelajaran: 'Bahasa Arab',
                nilai: 87,
              },
              {
                nama_mata_pelajaran: "Pend. Al Qur'an",
                nilai: 93,
              },
            ],
          },
        ],
      },
    ],
  },
  data_kop_surat: {
    kota: 'JAKARTA',
    email: 'smpalbayan2015@gmail.com',
    alamat: 'JL. BASOKA RAYA KAVLING HANKAM BLOK R NO.06',
    header: 'DINAS PENDIDIKAN PROVINSI DKI JAKARTA',
    telepon: 5840929,
    nomor_skl: '421.308/KET.03/SMP-AIS/VI/2022',
    nama_kepsek: 'Ade Badar Taufiq, ST',
    tanggal_skl: '15 Juni 2022',
    nama_sekolah: 'SMP AL BAYAN ISLAMIC SCHOOL',
    tahun_ajaran: '2021/2022',
  },
}
